.container-main {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  // background-color: #ccb77b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.room-container-main {
  min-width: 40%;
  max-width: 40%;
  text-align: center;
}

.room-card-main {
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.room-card-body {
  padding: 15px 0;
  width: 100%;
  text-align: center;
}

.room-card-title {
  font-size: 18px;
}
.room-container-items {
  display: flex;
  margin-bottom: 10px;
  font-size: 16px;
  flex-direction: column;
  align-items: center;
}
.room-container-items p {
  width: 95%;
  cursor: default;
}
.room-icon-success {
  font-size: 40px;
  color: #34c38f;
}

.card-room-item-main {
  margin-bottom: 8px;
  width: 90%;
  font-smooth: always;
  border: 1px solid;
  border-color: #d3d3d3;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.card-room-item-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-room-item-body p {
  margin: 0;
  text-align: left;
  font-size: 14px;
}

.card-room-item-badge-container {
  margin-left: "10px";
}

.card-room-item-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-room-item-button span {
  font-size: 14px;
  margin-top: 2px;
}

.footer-text {
  text-align: center;
  border-top: 1px solid #E0E0E0;	 
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.75);
}

@media (max-width: 425px) {
  .room-container-main {
    min-width: 90%;
    max-width: 90%;
  }
}
